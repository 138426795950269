<template>
  <b-container fluid>
    <b-row>
      <b-col md="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Configuración General</h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Nombre:"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    placeholder="Enter Your name"
                    v-model="form.name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Dirección:"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    placeholder="Enter Your address"
                    v-model="form.address"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Correo Electrónico:"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    placeholder="Enter Your email"
                    v-model="form.email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Teléfono:"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    placeholder="Enter Your phone"
                    v-model="form.phone"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Logo:"
                  label-for="logo"
                >
                  <b-form-file
                    id="logo"
                    v-model="form.logo"
                    :state="Boolean(form.logo)"
                    placeholder="Elija un archivo o suéltelo aquí..."
                    drop-placeholder="Suelta el archivo aquí..."
                    @change="onChangeFile"
                  ></b-form-file>
                </b-form-group>
                <b-button
                  v-user-can="'actualizar configuracion'"
                  type="submit"
                  variant="primary"
                  >Actualizar</b-button
                >
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
      <b-col md="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Logo</h4>
          </template>
          <template v-slot:body>
            <div class="text-center">
              <img :src="`${logo}`" style="width: 100%" />
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Horarios de Atención</h4>
          </template>
          <template v-slot:body>
            <b-form @submit.stop.prevent="updateSchedule">
              <b-row v-for="(value, idx) in attentionSchedule" :key="idx" class="mb-2">
                <b-col md="2" style="margin: auto">
                  <label>{{ value.name }}:</label> <br />
                  <b-form-checkbox
                    v-model="value.attention"
                    :value="true"
                    :unchecked-value="false"
                    @change="toggleAttention(idx)"
                  >
                    {{ value.attention ? "Hay Atención" : "No Hay Atención" }}
                  </b-form-checkbox>
                </b-col>
                <b-col md="10">
                  <b-row v-for="(hour, index) in value.hours" :key="index" class="mb-2">
                    <b-col md="5">
                      <input
                        class="form-control"
                        type="time"
                        v-model="attentionSchedule[idx].hours[index].startTime"
                        :disabled="!value.attention"
                      />
                    </b-col>
                    <b-col md="5">
                      <input
                        class="form-control"
                        type="time"
                        v-model="attentionSchedule[idx].hours[index].startTime"
                        :disabled="!value.attention"
                      />
                    </b-col>
                    <b-col md="2" style="margin: auto">
                      <b-button
                        v-if="attentionSchedule[idx].hours.length === 1"
                        variant="primary"
                        @click="pushTimes(idx)"
                      >
                        <i class="fa fa-plus"></i>
                      </b-button>
                      <b-button
                        v-if="attentionSchedule[idx].hours.length === 2 && index === 1"
                        variant="danger"
                        @click="deleteTimes(idx, index)"
                      >
                        <i class="fa fa-minus"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12">
                  <hr />
                </b-col>
              </b-row>
              <b-button
                v-user-can="'actualizar configuracion'"
                type="submit"
                variant="primary"
                >Actualizar</b-button
              >
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import api from './../../../services/api'

export default {
  mounted () {
    xray.index()
    this.getConfig()
    this.getSchedules()
  },
  computed: {},
  methods: {
    async getConfig () {
      try {
        const { data } = await api.get('/general-configuration')
        this.form = data
        this.logo = `${this.form.logo}`
        this.form.logo = null
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async getSchedules () {
      try {
        const { data } = await api.get('/schedules')
        this.attentionSchedule = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async onSubmit () {
      try {
        const formData = new FormData()
        Object.entries(this.form).forEach(([key, value]) => {
          formData.append(key, value)
        })

        await api.post(`/general-configuration`, formData)

        Toast.fire({
          icon: 'success',
          title: 'Configuración Actualizada'
        })

        this.getConfig()
      } catch (error) {
        const errorMessage = error?.response?.data?.message || 'Error al actualizar la configuración'
        Toast.fire({
          icon: 'error',
          title: errorMessage
        })
      }
    },
    updateSchedule () {
      const isValid = this.validateAttentionSchedule(this.attentionSchedule)
      if (isValid) {
        Toast.fire({
          icon: 'success',
          title: 'El objeto attentionSchedule es válido.'
        })
        api
          .put(`/schedules`, this.attentionSchedule)
          .then(() => {
            Toast.fire({
              icon: 'success',
              title: 'Horarios Actualizados'
            })
            this.getSchedules()
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.message || 'Error al actualizar la configuración'
            Toast.fire({
              icon: 'error',
              title: errorMessage
            })
          })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'El objeto attentionSchedule no es válido.'
        })
      }
    },
    onChangeFile (e) {
      this.form.logo = e.target.files[0]
    },
    pushTimes (idx) {
      this.attentionSchedule[idx].hours.push({
        startTime: '08:00:00',
        finalTime: '20:00:00'
      })
    },
    deleteTimes (idx, index) {
      this.attentionSchedule[idx].hours.splice(index, 1)
    },
    validateAttentionSchedule (schedule) {
      for (const day of schedule) {
        const { name, attention, hours } = day

        if (!attention) {
          Toast.fire({
            icon: 'error',
            title: `${name} no requiere validación de horas debido a que attention es false.`
          })
          continue
        }

        const numAppointments = hours.length

        if (numAppointments > 2) {
          Toast.fire({
            icon: 'error',
            title: `${name} tiene más de 2 objetos.`
          })
          return false
        }

        if (numAppointments === 1) {
          const { startTime, finalTime } = hours[0]
          if (this.startTimeGreaterThanEndTime(startTime, finalTime)) {
            Toast.fire({
              icon: 'error',
              title: `${name} tiene startTime mayor que finalTime.`
            })
            return false
          }
        }

        if (numAppointments === 2) {
          const { startTime: start1, finalTime: final1 } = hours[0]
          const { startTime: start2, finalTime: final2 } = hours[1]

          if (this.startTimeGreaterThanEndTime(start1, final1)) {
            Toast.fire({
              icon: 'error',
              title: `El primer objeto de ${name} tiene startTime mayor que finalTime.`
            })
            return false
          }

          if (this.startTimeGreaterThanEndTime(start2, final2)) {
            Toast.fire({
              icon: 'error',
              title: `El segundo objeto de ${name} tiene startTime mayor que finalTime.`
            })
            return false
          }

          if (this.startTimeGreaterThanEndTime(final1, start2)) {
            Toast.fire({
              icon: 'error',
              title: `El segundo objeto de ${name} tiene startTime menor que finalTime del primer objeto.`
            })
            return false
          }
        }
      }

      return true
    },
    startTimeGreaterThanEndTime (startTime, finalTime) {
      const startTimeInMinutes = this.timeToMinutes(startTime)
      const finalTimeInMinutes = this.timeToMinutes(finalTime)
      return startTimeInMinutes >= finalTimeInMinutes
    },
    timeToMinutes (time) {
      const [hours, minutes, seconds] = time.split(':').map(Number)
      return hours * 60 + minutes + seconds / 60
    },
    toggleAttention (index) {
      if (!this.attentionSchedule[index].attention) {
        this.attentionSchedule[index].hours = [
          {
            startTime: null,
            finalTime: null
          }
        ]
      } else {
        this.attentionSchedule[index].hours = [
          {
            startTime: '08:00:00',
            finalTime: '20:00:00'
          }
        ]
      }
    }
  },
  data () {
    return {
      logo: null,
      form: {},
      attentionSchedule: [
        {
          name: 'Lunes',
          attention: true,
          hours: [
            { startTime: '08:00:00', finalTime: '13:00:00' },
            { startTime: '15:00:00', finalTime: '20:00:00' }
          ]
        },
        {
          name: 'Martes',
          attention: true,
          hours: [
            { startTime: '08:00:00', finalTime: '13:00:00' },
            { startTime: '15:00:00', finalTime: '20:00:00' }
          ]
        },
        {
          name: 'Miércoles',
          attention: true,
          hours: [
            { startTime: '08:00:00', finalTime: '13:00:00' },
            { startTime: '15:00:00', finalTime: '20:00:00' }
          ]
        },
        {
          name: 'Jueves',
          attention: true,
          hours: [
            { startTime: '08:00:00', finalTime: '13:00:00' },
            { startTime: '15:00:00', finalTime: '20:00:00' }
          ]
        },
        {
          name: 'Viernes',
          attention: true,
          hours: [{ startTime: '09:00:00', finalTime: '18:00:00' }]
        },
        {
          name: 'Sábado',
          attention: true,
          hours: [{ startTime: '09:00:00', finalTime: '15:00:00' }]
        },
        {
          name: 'Domingo',
          attention: false,
          hours: [{ startTime: null, finalTime: null }]
        }
      ]
    }
  }
}
</script>
